<template>
  <div class="global-box">
    <!--定位-->
    <div class="button-box">
      <a-button type="primary" @click="getCurrentPosition">定位</a-button>
      <a-button type="primary" @click="getPicturesNear">查看当前位置附近图片</a-button>
    </div>
    <!--地图图层-->
    <div class="map-box">
      <div id="map"></div>
    </div>
    <!--图片展示-->
    <div class="image-box" v-show="isPictureShow" ref="imageBoxRef">
      <RightCircleOutlined class="carousel-btn right-button" @click="next" v-show="isNextShow"/>
      <LeftCircleOutlined class="carousel-btn left-button" @click="pre" v-show="isPreShow"/>
      <a-image 
        width="100%" 
        src="https://aliyuncdn.antdv.com/vue.png" 
      />
      <span class="date">2021年8月7日14:29</span>
      <a-button type="primary" class="close-btn" @click="closeImageBox">关闭</a-button>
    </div>
  </div>
</template>

<script>
// import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined
	},
  data() {
    return {
      //地图本身
      map: null,
      imageList: [],
      isNextShow: true,
      isPreShow: true,
      index: 0
    }
  },
  methods: {
    //地图初始化
    init() {
      // eslint-disable-next-line no-undef
      let point = new BMap.Point(117.816405, 39.019237);
      // eslint-disable-next-line no-undef
      this.map = new BMap.Map("map", {
        coordsType: 5,
        enableBizAuthLogo: false,
        // eslint-disable-next-line no-undef
        mapType: BMAP_SATELLITE_MAP
      });
      this.map.centerAndZoom(point, 13);
      this.map.enableScrollWheelZoom(true);
      this.map.disableBizAuthLogo(); //关闭
      // eslint-disable-next-line no-undef
      this.map.addControl(new BMap.ScaleControl());
      // eslint-disable-next-line no-undef
      this.map.addControl(new BMap.OverviewMapControl());
      // eslint-disable-next-line no-undef
      this.map.addControl(
        // eslint-disable-next-line no-undef
        new BMap.MapTypeControl({
          // eslint-disable-next-line no-undef
          mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP, BMAP_SATELLITE_MAP]
        })
      );
      // eslint-disable-next-line no-undef
      this.map.addControl(new BMap.GeolocationControl());

      //加区域
      // 海滩1
      // eslint-disable-next-line no-undef
      let beach_1_1 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let beach_1_2 = new BMap.Point(117.81378196661903, 39.06251340098135);
      // eslint-disable-next-line no-undef
      let beach_1_3 = new BMap.Point(117.77158910487401, 39.07714589126867);
      // eslint-disable-next-line no-undef
      let beach_1_4 = new BMap.Point(117.77395669519076, 39.081229481876189);
      // eslint-disable-next-line no-undef
      let beach_1_5 = new BMap.Point(117.81900068882274, 39.065698500108499);

      // 海漂1
      // eslint-disable-next-line no-undef
      let float_1_1 = new BMap.Point(117.82371855575747, 39.06808342595199);
      // eslint-disable-next-line no-undef
      let float_1_2 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let float_1_3 = new BMap.Point(117.77395669519076, 39.081229481876189);
      // eslint-disable-next-line no-undef
      let float_1_4 = new BMap.Point(117.77586076006807, 39.084517834757367);
      // eslint-disable-next-line no-undef
      let float_1_5 = new BMap.Point(117.82371855575747, 39.06808342595199);

      // 海滩2
      // eslint-disable-next-line no-undef
      let beach_2_1 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let beach_2_2 = new BMap.Point(117.82103290947602, 39.03749143985976);
      // eslint-disable-next-line no-undef
      let beach_2_3 = new BMap.Point(117.81378196661903, 39.06251340098135);
      // eslint-disable-next-line no-undef
      let beach_2_4 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let beach_2_5 = new BMap.Point(117.82681672118544, 39.03872394147336);

      // 海漂2
      // eslint-disable-next-line no-undef
      let float_2_1 = new BMap.Point(117.83169246071212, 39.03974933415886);
      // eslint-disable-next-line no-undef
      let float_2_2 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let float_2_3 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let float_2_4 = new BMap.Point(117.82371855575747, 39.06808342595199);
      // eslint-disable-next-line no-undef
      let float_2_5 = new BMap.Point(117.83169246071212, 39.03974933415886);

      // 海滩3
      // eslint-disable-next-line no-undef
      let beach_3_1 = new BMap.Point(117.83698288200677, 39.020033970995388);
      // eslint-disable-next-line no-undef
      let beach_3_2 = new BMap.Point(117.82646624113764, 39.01865580692072);
      // eslint-disable-next-line no-undef
      let beach_3_3 = new BMap.Point(117.82103290947602, 39.03749143985976);
      // eslint-disable-next-line no-undef
      let beach_3_4 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let beach_3_5 = new BMap.Point(117.83152543389177, 39.02240307114145);
      // eslint-disable-next-line no-undef
      let beach_3_6 = new BMap.Point(117.83667164466046, 39.02163855214231);
      // eslint-disable-next-line no-undef
      let beach_3_7 = new BMap.Point(117.83698288200677, 39.020033970995388);

      // 海漂3
      // eslint-disable-next-line no-undef
      let float_3_1 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let float_3_2 = new BMap.Point(117.83169246071212, 39.03974933415886);
      // eslint-disable-next-line no-undef
      let float_3_3 = new BMap.Point(117.83667164466046, 39.02163855214231);
      // eslint-disable-next-line no-undef
      let float_3_4 = new BMap.Point(117.83152543389177, 39.02240307114145);
      // eslint-disable-next-line no-undef
      let float_3_5 = new BMap.Point(117.82681672118544, 39.03872394147336);

      // 海滩4
      // eslint-disable-next-line no-undef
      let beach_4_1 = new BMap.Point(117.82646624113764, 39.01865580692072);
      // eslint-disable-next-line no-undef
      let beach_4_2 = new BMap.Point(117.83698288200677, 39.020033970995388);
      // eslint-disable-next-line no-undef
      let beach_4_3 = new BMap.Point(117.83761835472298, 39.018365247317728);
      // eslint-disable-next-line no-undef
      let beach_4_4 = new BMap.Point(117.83620806862766, 39.0173200452412);
      // eslint-disable-next-line no-undef
      let beach_4_5 = new BMap.Point(117.83546980836316, 39.016567735300217);
      // eslint-disable-next-line no-undef
      let beach_4_6 = new BMap.Point(117.83550099015955, 39.01542243858008);
      // eslint-disable-next-line no-undef
      let beach_4_7 = new BMap.Point(117.83866002531846, 39.00224131168685);
      // eslint-disable-next-line no-undef
      let beach_4_8 = new BMap.Point(117.84308448338861, 39.003029840997509);
      // eslint-disable-next-line no-undef
      let beach_4_9 = new BMap.Point(117.84382896231145, 38.999922513122147);
      // eslint-disable-next-line no-undef
      let beach_4_10 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let beach_4_11 = new BMap.Point(117.83243814134167, 38.997898345009968);
      // eslint-disable-next-line no-undef
      let beach_4_12 = new BMap.Point(117.82646624113764, 39.01865580692072);

      // 海漂4
      // eslint-disable-next-line no-undef
      let float_4_1 = new BMap.Point(117.84308448338861, 39.003029840997509);
      // eslint-disable-next-line no-undef
      let float_4_2 = new BMap.Point(117.83866002531846, 39.00224131168685);
      // eslint-disable-next-line no-undef
      let float_4_3 = new BMap.Point(117.83550099015955, 39.01542243858008);
      // eslint-disable-next-line no-undef
      let float_4_4 = new BMap.Point(117.83546980836316, 39.016567735300217);
      // eslint-disable-next-line no-undef
      let float_4_5 = new BMap.Point(117.83620806862766, 39.0173200452412);
      // eslint-disable-next-line no-undef
      let float_4_6 = new BMap.Point(117.83761835472298, 39.018365247317728);
      // eslint-disable-next-line no-undef
      let float_4_7 = new BMap.Point(117.8384719607511, 39.018347543211458);
      // eslint-disable-next-line no-undef
      let float_4_8 = new BMap.Point(117.83978767656461, 39.01779603050196);
      // eslint-disable-next-line no-undef
      let float_4_9 = new BMap.Point(117.84170582553414, 39.016738947479208);
      // eslint-disable-next-line no-undef
      let float_4_10 = new BMap.Point(117.84311732391645, 39.015476103384099);
      // eslint-disable-next-line no-undef
      let float_4_11 = new BMap.Point(117.84441913321513, 39.01367793100998);
      // eslint-disable-next-line no-undef
      let float_4_12 = new BMap.Point(117.845713824849, 39.011305537871617);
      // eslint-disable-next-line no-undef
      let float_4_13 = new BMap.Point(117.84625448364749, 39.00861173921601);
      // eslint-disable-next-line no-undef
      let float_4_14 = new BMap.Point(117.8455191204376, 39.006067145913);
      // eslint-disable-next-line no-undef
      let float_4_15 = new BMap.Point(117.8447794885481, 39.004790072048127);
      // eslint-disable-next-line no-undef
      let float_4_16 = new BMap.Point(117.84381388550297, 39.00369347260906);
      // eslint-disable-next-line no-undef
      let float_4_17 = new BMap.Point(117.84308448338861, 39.003029840997509);


      //加区域
      // eslint-disable-next-line no-undef
      let beach_1 = new BMap.Polygon(
          [beach_1_1, beach_1_2, beach_1_3, beach_1_4, beach_1_5],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_1);

      // eslint-disable-next-line no-undef
      let beach_2 = new BMap.Polygon(
          [beach_2_1, beach_2_2, beach_2_3, beach_2_4, beach_2_5],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_2);

      // eslint-disable-next-line no-undef
      let beach_3 = new BMap.Polygon(
          [beach_3_1, beach_3_2, beach_3_3, beach_3_4, beach_3_5, beach_3_6, beach_3_7],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_3);

      // eslint-disable-next-line no-undef
      let beach_4 = new BMap.Polygon(
          [beach_4_1, beach_4_2, beach_4_3, beach_4_4, beach_4_5, beach_4_6, beach_4_7, beach_4_8, beach_4_9, beach_4_10, beach_4_11, beach_4_12],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_4);

      // eslint-disable-next-line no-undef
      let float_1 = new BMap.Polygon(
          [float_1_1, float_1_2, float_1_3, float_1_4, float_1_5],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_1);

      // eslint-disable-next-line no-undef
      let float_2 = new BMap.Polygon(
          [float_2_1, float_2_2, float_2_3, float_2_4, float_2_5],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_2);

      // eslint-disable-next-line no-undef
      let float_3 = new BMap.Polygon(
          [float_3_1, float_3_2, float_3_3, float_3_4, float_3_5,],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_3);

      // eslint-disable-next-line no-undef
      let float_4 = new BMap.Polygon(
          [float_4_1, float_4_2, float_4_3, float_4_4, float_4_5, float_4_6, float_4_7, float_4_8, float_4_9, float_4_10, float_4_11, float_4_12, float_4_13, float_4_14, float_4_15, float_4_16, float_4_17],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_4);

      // eslint-disable-next-line no-undef
      let beach_5_1 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let beach_5_2 = new BMap.Point(117.836325984016, 38.98439342240584);
      // eslint-disable-next-line no-undef
      let beach_5_3 = new BMap.Point(117.83243814134167, 38.997898345009968);
      // eslint-disable-next-line no-undef
      let beach_5_4 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let beach_5_5 = new BMap.Point(117.84257549925502, 38.98382773693862);

      // eslint-disable-next-line no-undef
      let float_5_1 = new BMap.Point(117.84740972723692, 38.98499128135028);
      // eslint-disable-next-line no-undef
      let float_5_2 = new BMap.Point(117.84314405700256, 38.98152934313679);
      // eslint-disable-next-line no-undef
      let float_5_3 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let float_5_4 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let float_5_5 = new BMap.Point(117.84382896231145, 38.999922513122147);
      // eslint-disable-next-line no-undef
      let float_5_6 = new BMap.Point(117.84740972723692, 38.98499128135028);

      // eslint-disable-next-line no-undef
      let beach_6_1 = new BMap.Point(117.836325984016, 38.98439342240584);
      // eslint-disable-next-line no-undef
      let beach_6_2 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let beach_6_3 = new BMap.Point(117.84314405700256, 38.98152934313679);
      // eslint-disable-next-line no-undef
      let beach_6_4 = new BMap.Point(117.84068725569577, 38.97952486730796);
      // eslint-disable-next-line no-undef
      let beach_6_5 = new BMap.Point(117.82357454979995, 38.981675555739048);
      // eslint-disable-next-line no-undef
      let beach_6_6 = new BMap.Point(117.82181823709257, 38.98034784820357);
      // eslint-disable-next-line no-undef
      let beach_6_7 = new BMap.Point(117.80474113454149, 38.98985402441064);
      // eslint-disable-next-line no-undef
      let beach_6_8 = new BMap.Point(117.80144794141202, 38.99353310977236);
      // eslint-disable-next-line no-undef
      let beach_6_9 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let beach_6_10 = new BMap.Point(117.808126863064, 38.995339566936397);
      // eslint-disable-next-line no-undef
      let beach_6_11 = new BMap.Point(117.80901301361809, 38.99341457990975);
      // eslint-disable-next-line no-undef
      let beach_6_12 = new BMap.Point(117.81424363993087, 38.990099488818298);
      // eslint-disable-next-line no-undef
      let beach_6_13 = new BMap.Point(117.81975228153212, 38.987433928942177);
      // eslint-disable-next-line no-undef
      let beach_6_14 = new BMap.Point(117.82418803200905, 38.98611374560856);
      // eslint-disable-next-line no-undef
      let beach_6_15 = new BMap.Point(117.836325984016, 38.98439342240584);

      // eslint-disable-next-line no-undef
      let float_6_1 = new BMap.Point(117.7971991753089, 38.994114976252017);
      // eslint-disable-next-line no-undef
      let float_6_2 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let float_6_3 = new BMap.Point(117.80144794141202, 38.99353310977236);
      // eslint-disable-next-line no-undef
      let float_6_4 = new BMap.Point(117.80474113454149, 38.98985402441064);
      // eslint-disable-next-line no-undef
      let float_6_5 = new BMap.Point(117.82181823709257, 38.98034784820357);
      // eslint-disable-next-line no-undef
      let float_6_6 = new BMap.Point(117.82357454979995, 38.981675555739048);
      // eslint-disable-next-line no-undef
      let float_6_7 = new BMap.Point(117.84068725569577, 38.97952486730796);
      // eslint-disable-next-line no-undef
      let float_6_8 = new BMap.Point(117.83781149462408, 38.9771697476575);
      // eslint-disable-next-line no-undef
      let float_6_9 = new BMap.Point(117.8252979180732, 38.97881484370927);
      // eslint-disable-next-line no-undef
      let float_6_10 = new BMap.Point(117.82373942321326, 38.97758556544104);
      // eslint-disable-next-line no-undef
      let float_6_11 = new BMap.Point(117.82047307889886, 38.9779137750992);
      // eslint-disable-next-line no-undef
      let float_6_12 = new BMap.Point(117.80195711412149, 38.987844410621338);
      // eslint-disable-next-line no-undef
      let float_6_13 = new BMap.Point(117.79772644845633, 38.992123492550508);
      // eslint-disable-next-line no-undef
      let float_6_14 = new BMap.Point(117.7971991753089, 38.994114976252017);

      // eslint-disable-next-line no-undef
      let beach_7_1 = new BMap.Point(117.808126863064, 38.995339566936397);
      // eslint-disable-next-line no-undef
      let beach_7_2 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let beach_7_3 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let beach_7_4 = new BMap.Point(117.799494969824, 39.028229590037167);
      // eslint-disable-next-line no-undef
      let beach_7_5 = new BMap.Point(117.808126863064, 38.995339566936397);

      // eslint-disable-next-line no-undef
      let float_7_1 = new BMap.Point(117.78865142443694, 39.02637168831018);
      // eslint-disable-next-line no-undef
      let float_7_2 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let float_7_3 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let float_7_4 = new BMap.Point(117.7971991753089, 38.994114976252017);
      // eslint-disable-next-line no-undef
      let float_7_5 = new BMap.Point(117.78865142443694, 39.02637168831018);

      // eslint-disable-next-line no-undef
      let beach_8_1 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let beach_8_2 = new BMap.Point(117.78575570283162, 39.0530681478733);
      // eslint-disable-next-line no-undef
      let beach_8_3 = new BMap.Point(117.77466875334567, 39.05724665067165);
      // eslint-disable-next-line no-undef
      let beach_8_4 = new BMap.Point(117.77679078302437, 39.060773693539498);
      // eslint-disable-next-line no-undef
      let beach_8_5 = new BMap.Point(117.792380241949, 39.055170535969718);
      // eslint-disable-next-line no-undef
      let beach_8_6 = new BMap.Point(117.799494969824, 39.028229590037167);
      // eslint-disable-next-line no-undef
      let beach_8_7 = new BMap.Point(117.7926448231615, 39.02704338722681);

      // eslint-disable-next-line no-undef
      let float_8_1 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let float_8_2 = new BMap.Point(117.78865142443694, 39.02637168831018);
      // eslint-disable-next-line no-undef
      let float_8_3 = new BMap.Point(117.78218759814257, 39.05062672712913);
      // eslint-disable-next-line no-undef
      let float_8_4 = new BMap.Point(117.77281891716619, 39.05417636480129);
      // eslint-disable-next-line no-undef
      let float_8_5 = new BMap.Point(117.77466875334567, 39.05724665067165);
      // eslint-disable-next-line no-undef
      let float_8_6 = new BMap.Point(117.78575570283162, 39.0530681478733);
      // eslint-disable-next-line no-undef
      let float_8_7 = new BMap.Point(117.7926448231615, 39.02704338722681);

      // eslint-disable-next-line no-undef
      let beach_5 = new BMap.Polygon(
          [beach_5_1, beach_5_2, beach_5_3, beach_5_4, beach_5_5],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_5);

      // eslint-disable-next-line no-undef
      let float_5 = new BMap.Polygon(
          [float_5_1, float_5_2, float_5_3, float_5_4, float_5_5, float_5_6],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_5);

      // eslint-disable-next-line no-undef
      let beach_6 = new BMap.Polygon(
          [beach_6_1, beach_6_2, beach_6_3, beach_6_4, beach_6_5, beach_6_6, beach_6_7, beach_6_8, beach_6_9, beach_6_10,
            beach_6_11, beach_6_12, beach_6_13, beach_6_14, beach_6_15],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_6);

      // eslint-disable-next-line no-undef
      let float_6 = new BMap.Polygon(
          [float_6_1, float_6_2, float_6_3, float_6_4, float_6_5, float_6_6, float_6_7, float_6_8, float_6_9, float_6_10,
            float_6_11, float_6_12, float_6_13, float_6_14],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_6);

      // eslint-disable-next-line no-undef
      let beach_7 = new BMap.Polygon(
          [beach_7_1, beach_7_2, beach_7_3, beach_7_4, beach_7_5],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_7);

      // eslint-disable-next-line no-undef
      let float_7 = new BMap.Polygon(
          [float_7_1, float_7_2, float_7_3, float_7_4, float_7_5],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_7);

      // eslint-disable-next-line no-undef
      let beach_8 = new BMap.Polygon(
          [beach_8_1, beach_8_2, beach_8_3, beach_8_4, beach_8_5, beach_8_6, beach_8_7],
          {
            strokeColor: "#4ec21b",
            fillColor: "#8ffc01",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(beach_8);

      // eslint-disable-next-line no-undef
      let float_8 = new BMap.Polygon(
          [float_8_1, float_8_2, float_8_3, float_8_4, float_8_5, float_8_6, float_8_7],
          {
            strokeColor: "#15276F",
            fillColor: "#22A7F2",
            fillOpacity: 0.25,
            strokeWeight: "5px"
          });
      this.map.addOverlay(float_8);

      // eslint-disable-next-line no-undef
      let label_1 = new BMap.Label("1", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.800307, 39.071021)
      });
      label_1.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_1);

      // eslint-disable-next-line no-undef
      let label_2 = new BMap.Label("2", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.820729, 39.04816)
      });
      label_2.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_2);

      // eslint-disable-next-line no-undef
      let label_3 = new BMap.Label("3", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.823341, 39.029552)
      });
      label_3.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_3);

      // eslint-disable-next-line no-undef
      let label_4 = new BMap.Label("4", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.828377, 39.013594)
      });
      label_4.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_4);

      // eslint-disable-next-line no-undef
      let label_5 = new BMap.Label("5", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.838926, 38.990078)
      });
      label_5.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_5);

      // eslint-disable-next-line no-undef
      let label_6 = new BMap.Label("6", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.810368, 38.989405)
      });
      label_6.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_6);

      // eslint-disable-next-line no-undef
      let label_7 = new BMap.Label("7", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.798583, 39.012958)
      });
      label_7.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_7);

      // eslint-disable-next-line no-undef
      let label_8 = new BMap.Label("8", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.790965, 39.04065)
      });
      label_8.setStyle({
        color: 'black',
        border: '0',
        background: "transparent",
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: '微软雅黑'
      });
      this.map.addOverlay(label_8);
    },
    //定位
    getCurrentPosition() {
      // let options = {
      //   enableHighAccuracy: true,
      //   timeout: 5000,
      //   maximumAge: 0
      // };

      // 百度地图api
      // eslint-disable-next-line no-undef
      let geolocation = new BMap.Geolocation();
      const that = this;
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function(result) {
        // eslint-disable-next-line no-undef
        if (this.getStatus() === BMAP_STATUS_SUCCESS) {
          // eslint-disable-next-line no-undef
          let marker = new BMap.Marker(result.point);
          that.map.addOverlay(marker);
          that.map.setCenter(result.point);
        } else {
          alert("获取位置信息失败！");
        }
      });

      // //浏览器自带api
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(this.showPosition, this.showError, options);
      // } else {
      //   alert("不支持！");
      // }
    },
    showPosition(position) {
      alert(1);
      let longitude = position.coords.longitude;
      let latitude = position.coords.latitude;
      alert(longitude, latitude);
      // eslint-disable-next-line no-undef
      let point = new BMap.Point(longitude, latitude);
      // eslint-disable-next-line no-undef
      let trans = new BMap.Convertor();
      let pointArr = [];
      pointArr.push(point);
      trans.translate(pointArr, 1, 5, (data) => {
        // eslint-disable-next-line no-undef
        let rightPoint = new BMap.Point(data.points[0].lng, data.points[0].lat);
        // eslint-disable-next-line no-undef
        let marker = new BMap.Marker(rightPoint);
        this.map.addOverlay(marker);
        this.map.centerAndZoom(rightPoint);
      });
    },
    showError(error) {
      switch(error.code)
      {
        case error.PERMISSION_DENIED:
          alert("您拒绝对获取地理位置的请求");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("位置信息是不可用的");
          break;
        case error.TIMEOUT:
          alert("请求您的地理位置超时");
          break;
        case error.UNKNOWN_ERROR:
          alert("未知错误");
          break;
      }
    },
    getPicturesNear() {
      this.$refs.imageBoxRef.style.display = "block";
    },
    closeImageBox() {
      this.$refs.imageBoxRef.style.display = "none";
    },
    next() {

    },
    pre() {

    }
  },
  mounted() {
    this.init();
  }
})
</script>

<style lang="scss" scoped>
@media screen and (orientation:portrait) {
  .global-box {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    .button-box {
      display: flex;
      width: 80%;
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 900;
      button {
        margin-right: 5px;
      }
    }
    .map-box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 19;
      #map {
        width: 100%;
        height: 100%;
      }
    }
    .image-box {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.6);
      .carousel-btn {
        font-size: 50px;
        height: 70px;
        width: 70px;
        z-index: 200;
        color: #FFF;
      }
      .right-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-2px, -50%);
      }
      .left-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(2px, -50%);
      }
      .date {
        display: block;
        height: 32px;
        width: 240px;
        color: #fff;
        font-size: 15px;
        background: #1890ff;
        padding: 5px 15px;
        z-index: 20;
        position: absolute;
        top: 15%;
        left: 3px;
      }
      .close-btn {
        position: absolute;
        top: 15%;
        left: 249px;
        background-color: red;
        border: 0;
      }
    }
  }
  :deep().ant-image-img {
    padding: 3px;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 30%) !important;
  }
}
@media screen and (orientation:landscape) {
  .global-box {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    .button-box {
      display: flex;
      width: 100%;
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 900;
      button {
        margin-right: 5px;
      }
    }
    .map-box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 19;
      #map {
        width: 100%;
        height: 100%;
      }
    }
    .image-box {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.6);
      .carousel-btn {
        font-size: 50px;
        height: 70px;
        width: 70px;
        z-index: 200;
        color: #FFF;
      }
      .right-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
      }
      .left-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(50%, -50%);
      }
      .date {
        display: block;
        height: 32px;
        width: 220px;
        color: #fff;
        font-size: 15px;
        background: #1890ff;
        padding: 5px 15px;
        z-index: 20;
        position: absolute;
        top: 5%;
        left: 3px;
      }
      .close-btn {
        position: absolute;
        top: 5%;
        right: 5%;
        background-color: red;
        border: 0;
      }
    }
  }
  :deep().ant-image-img {
    width: 40%;
    padding: 3px;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
  }
}
</style>